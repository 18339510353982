* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.7;
  background: #eee;
  color: #333;
}

.form-group,
.form-group label,
.form-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group label {
  font-size: 0.8rem;
}

.form-group input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.form-group input:focus {
  border-bottom: 3px solid coral;
}

.btn {
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
}

.btn:hover {
  opacity: 0.8;
}

.btn-primary {
  background-color: coral;
  color: #fff;
}

.error-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: red;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}

.success-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: green;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}


.PhoneInputCountryIconImg {
  display: none !important;
  width: 100%;
  height: 100%;
}
.PhoneInputCountryIcon {
  display: none !important;
}

.MuiTabs-root {
  display: none;
}

.MuiTextField-root {
  margin: 8px;
  width: 100% !important;
}

.PhoneInputCountrySelectArrow {
  font-size:30px;
  display: flex;
}


#meetingSDKElement {
  top: 59px !important;
  left: 0 !important;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 244px; /* width of speaker and ribbon view */
}

.btn-primary {
  background-color: #1565c0 ;
  color: #fff;
}

.form-group input:focus {
  border-bottom: 3px solid #1565c060 !important;
}

.active {
  background-color: #007bff93 !important ;
}

#zoom-sdk-video-canvas {
  max-width: 100vw;
  max-height: 90vh;
margin: auto !important ;

}

.react-resizable {
 width: 100%;
  max-width: 100vw;
  max-height: 90vh;
    display: block;
    margin: auto;
}

.MuiButton-iconSizeSmall > *:first-child {
  font-size: 30px !important;
}

.but {
  margin-bottom: 0.5rem; 
  width: 90px !important;
  padding: 10px 10px !important;
}

.app {
  width: 100vw !important
}

.active {
  background-color: #5c687693 !important;
}

.notLink:hover {
text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}

@media (max-width: 450px) {
  .just {
    justify-content: space-between !important;
  }
  .justBut {
    padding: 7px 0px !important;
    font-Size: 12px;
    max-width:60px;
  }
}

.react-simple-image-viewer__previous {
  display: none !important;
}


.react-simple-image-viewer__next {
  display: none !important;
}

.styles-module_close__2I1sI {
  opacity: 0.4 !important;
  font-size: 100px !important;
}

#transition-popper {
  z-index: 1000000000;
}
.loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #e6e6e6;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}





.wrapper {
  background-color: white;
  width: 400px;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  font: inherit;
  border-radius: 0.2rem;
  border: 2px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.input:focus {
  border-color: cornflowerblue;
  outline: none;
}

.input.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.ql-snow {
  box-sizing: border-box;
  background-color: white;
}
/* .ql-editor { height: 200px !important } */

#firstQuill .ql-container {
  Height: 200px;
}

#panel1a-header {
  cursor: initial ;
}